<template>
    <div v-if="(path && !pending && data?.data) || isAdmin" class="flex gap-3 items-center whitespace-pre-wrap break-words">
        <ElementsIcon v-if="!noIcon" icon="messageBubble" />
        <div>
            <NuxtLink v-if="isAdmin" :to="{ name: 'configuration-help-text-slug', params: { slug: path } }" class="block mb-2">
                <p>Hilfe-Text: {{path}}</p>
                <p class="flex items-center">Jetzt bearbeiten <ElementsIcon icon="carretLeft" /></p>
            </NuxtLink>
            <p class="text-xl">{{ data?.data?.title || data?.data?.attributes?.title }}</p>
            <p class="text-base text-gray-800 dark:text-gray-300">{{ data?.data?.text || data?.data?.attributes?.text }}</p>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    path: { type: String, default: null },
    noIcon: { type: Boolean, default: false },
})
const { findOne } = useStrapi4()
const isAdmin = useIsAdmin()


const { data, pending } = useLazyAsyncData(
    'help-'+props.path,
    () => findOne('help-texts', props.path)
)
</script>