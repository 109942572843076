<template>
    <form @submit.prevent="handleSubmit">
        <ElementsHelpText v-if="path" :noIcon="noHelpTextIcon" :path="'form.' + path" class="pb-4" />
        <slot :validation="validation" />
    </form>
</template>
<script setup>
const props = defineProps({
    submit: {  default: () => {} },
    path: { type: String, default: null },
    noHelpTextIcon: { type: Boolean, default: false },
})
const emit = defineEmits(['submit', 'error'])

const handleSubmit = () => {
    validation.check() ? emit('submit') : emit('error')
}

let autocheck = false
const validations = ref([])

const validation = {
    add: (key, fn) => {
        validations.value.push({ key: key, fn: fn })
    },
    check: (disableAutoCheck) => {
        let valid = true
        let validPerKey = []
        autocheck = !disableAutoCheck
        validations.value.forEach(val => {
            if(validPerKey[val.key] == null) validPerKey[val.key] = true

            if(!val.fn(validPerKey[val.key]) ){
                valid = false
                validPerKey[val.key] = false
            }
        })
        return valid
    },
    autovalidate: () => {
        if(autocheck)
            validation.check()
    },
    remove: (key) => {
        validations.value = validations.value.filter(val => val.key !== key)
    }
}

</script>